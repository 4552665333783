import React from "react"
import SvgIcon from "@material-ui/core/SvgIcon"

export default (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 50 50">
      <g id="Working">
        <path
          style={props.color}
          d="M21.17,42.88H13.76a1,1,0,0,1-1-1v-7.5a1,1,0,0,1,1-1h7.41a1,1,0,0,1,1,1v7.5A1,1,0,0,1,21.17,42.88Zm-6.41-2h5.41v-5.5H14.76Z"
        />
        <path
          style={props.color}
          d="M31,39.17a5.24,5.24,0,1,1,5.22-5.24A5.23,5.23,0,0,1,31,39.17Zm0-8.48a3.24,3.24,0,1,0,3.22,3.24A3.23,3.23,0,0,0,31,30.69Z"
        />
        <path
          style={props.color}
          d="M20.22,29.75H10.85a1,1,0,0,1-.87-.51,1,1,0,0,1,0-1l4.69-7.82a1,1,0,0,1,1.71,0l4.69,7.82a1,1,0,0,1,0,1A1,1,0,0,1,20.22,29.75Zm-7.61-2h5.85l-2.92-4.87Z"
        />
        <path
          style={props.color}
          d="M23.67,48.5c-6.39,0-16.5-1.45-16.63-5.34L2.51,9.55a1,1,0,0,1,2-.39c.5,1.93,8.05,4.63,19.2,4.63s18.69-2.7,19.19-4.63a1,1,0,0,1,2,.39L40.29,43.16c0,1.32-1.22,2.45-3.51,3.36a22.87,22.87,0,0,1-3.12.94A46,46,0,0,1,23.67,48.5ZM4.9,12.27,9,43s0,.09,0,.13c0,1.18,5.69,3.42,14.63,3.42a43.75,43.75,0,0,0,9.54-1A21,21,0,0,0,36,44.66c1.85-.73,2.26-1.39,2.26-1.58V43l4.14-30.68c-3.86,2.3-11.64,3.52-18.77,3.52S8.75,14.57,4.9,12.27Z"
        />
        <path
          style={props.color}
          d="M23.67,15.79c-9.47,0-20.1-2.15-21.14-6.12,0-.05,0-.09,0-.13l0-.41a.62.62,0,0,1,0-.13c0-4.42,11.18-7.5,21.22-7.5S44.88,4.58,44.88,9v.13l-.06.41a.69.69,0,0,1,0,.13C43.76,13.64,33.13,15.79,23.67,15.79ZM4.48,9.21c.62,1.93,8.14,4.58,19.19,4.58s18.56-2.65,19.18-4.58l0-.27C42.75,7,34.86,3.5,23.67,3.5S4.59,7,4.45,8.94Z"
        />
        <path
          style={props.color}
          d="M44.93,29.62c-4.76,0-15.18-4.71-21.7-7.91l.88-1.8c9,4.42,19.6,8.52,21.41,7.75-.14-.29-.81-1.19-4-2.92l-.16-.1,1-1.74.15.09c3.21,1.77,4.74,3.13,5,4.41a1.82,1.82,0,0,1-.4,1.52A2.77,2.77,0,0,1,44.93,29.62Z"
        />
        <path
          style={props.color}
          d="M23.67,23a2.17,2.17,0,1,1,2.17-2.17A2.18,2.18,0,0,1,23.67,23Zm0-2.34a.17.17,0,0,0-.18.17c0,.19.35.19.35,0A.17.17,0,0,0,23.67,20.64Z"
        />
      </g>
    </SvgIcon>
  )
}
